<template>
  <v-content>
    <v-card>
      <v-container
        fluid
        v-for="eventList in eventClassificationList"
        :key="eventList"
      >
        <v-row v-if="eventList.length" align-content="stretch">
          <v-col cols="auto" v-for="event in eventList" :key="event.id">
            <!-- detail:: [ {{ event.wowwow }} ] -->
            <v-card
              class="mx-auto"
              width="355"
              max-width="700"
              min-height="580"
            >
              <!-- <a v-if="dialog = true" @click.stop="dialog = true" v-bind:href="event.url" /> -->
              <a @click="openWindow(event.url)">
                <v-img :src="event.image" />
              </a>
              <v-card-text class="text--primary">
                <v-row v-if="event.name">
                  <v-icon color="black darken-2">mdi-play-circle</v-icon>
                  {{ event.name }}
                </v-row>
                <v-row v-if="event.city">
                  <v-icon color="green darken-2">mdi-google-maps</v-icon>
                  {{ event.city }}, {{ event.country }}
                </v-row>
                <v-row v-if="event.venue">
                  <v-icon color="orange darken-2">mdi-domain</v-icon>
                  {{ event.venue }}
                </v-row>
                <v-row v-if="event.startTime">
                  <v-icon color="blue darken-2">mdi-calendar</v-icon>
                  ({{ event.timeZoneName }})
                  <h4>{{ event.startTime }} - {{ event.endTime }}</h4>
                </v-row>
                <v-row v-if="event.startTime">
                  <v-icon color="gray">mdi-calendar</v-icon>
                  ({{ event.localTimezone }}) {{ event.startLocalTime }} -
                  {{ event.endLocalTime }}
                </v-row>
                <v-row
                  v-if="event.description"
                  style="padding-left: 10%"
                  align="left"
                >
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn icon @click="event.show = !event.show">
                      <v-icon>{{
                        event.show ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon>
                      Description
                    </v-btn>
                  </v-card-actions>

                  <v-expand-transition>
                    <div v-show="event.show">
                      <v-divider></v-divider>

                      <v-card-text>
                        {{ event.description }}
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row v-else class="justify-center">
          <v-col cols="auto">
            <v-card-text><h2>no event</h2></v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-spacer style="height: 100px" />
  </v-content>
</template>

<script>
//3O4PSX402A1MOwQStwsNs6S25jMr0TMn :: test api key
//XVC51muVxPkTTG4AMJeDcGHwrSXyrDkG :: prod api key
const discoveryApiKey = "XVC51muVxPkTTG4AMJeDcGHwrSXyrDkG";
const discoveryUrl = "https://app.ticketmaster.com/discovery/v2";
const eventsUrl = "/events.json";

// "KZFzBErXgnZfZ7vAFE" is events which has classification - subtype is "Virtual"
const classificationIdVirtual = "KZFzBErXgnZfZ7vAFE";

//affiliate code.
const affiliateCodeMap = {
  AT: {
    country: "Austria",
    country_code: "AT",
    affiliate_code:
      "https://ticketmaster-at.tm8116.net/c/3148594/454812/8116?u=",
  },
  AU: {
    country: "Australia",
    country_code: "AU",
    affiliate_code:
      "https://ticketmaster-au.tm7566.net/c/3148594/431533/7566?u=",
  },
  BE: {
    country: "Belgium",
    country_code: "BE",
    affiliate_code:
      "https://ticketmaster-be.tm7522.net/c/3148594/427771/7522?u=",
  },
  CA: {
    country: "Canada",
    country_code: "CA",
    affiliate_code: "https://ticketmaster.evyy.net/c/3148594/271177/4272?u=",
  },
  CH: {
    country: "Switzerland",
    country_code: "CH",
    affiliate_code:
      "https://ticketmaster-ch.tm8186.net/c/3148594/458657/8186?u=",
  },
  CZ: {
    country: "The Czech Republic",
    country_code: "CZ",
    affiliate_code:
      "https://ticketmaster-cz.tm9743.net/c/3148594/591899/9743?u=",
  },
  DE: {
    country: "Germany",
    country_code: "DE",
    affiliate_code:
      "https://ticketmaster-de.tm7514.net/c/3148594/427757/7514?u=",
  },
  DK: {
    country: "Denmark",
    country_code: "DK",
    affiliate_code:
      "https://ticketmaster-dk.tm7521.net/c/3148594/427769/7521?u=",
  },
  ES: {
    country: "Spain",
    country_code: "ES",
    affiliate_code:
      "https://ticketmaster-es.tm7508.net/c/3148594/427744/7508?u=",
  },
  FI: {
    country: "Finland",
    country_code: "FI",
    affiliate_code:
      "https://ticketmaster-fi.tm7520.net/c/3148594/427767/7520?u= ",
  },
  FR: {
    country: "France",
    country_code: "FR",
    affiliate_code:
      "https://ticketmaster-fr.tm7516.net/c/3148594/427761/7516?u=",
  },
  GB: {
    country: "The United Kingdom of Great Britain and Northern Ireland",
    country_code: "GB",
    affiliate_code:
      "https://ticketmaster-uk.tm7562.net/c/3148594/431525/7562?u=",
  },
  IE: {
    country: "Ireland",
    country_code: "IE",
    affiliate_code:
      "https://ticketmaster-ie.tm7512.net/c/3148594/427753/7512?u=",
  },
  IT: {
    country: "Italy",
    country_code: "IT",
    affiliate_code:
      "https://ticketmaster-italy.46uy.net/c/3148594/458790/8188?u=",
  },
  MX: {
    country: "Mexico",
    country_code: "MX",
    affiliate_code: "https://imp.i284037.net/c/3148594/816419/11559?u=",
  },
  NL: {
    country: "Netherlands",
    country_code: "NL",
    affiliate_code:
      "https://ticketmaster-nl.tm7510.net/c/3148594/427748/7510?u=",
  },
  NO: {
    country: "Norway",
    country_code: "NO",
    affiliate_code:
      "https://ticketmaster-no.tm8215.net/c/3148594/462382/8215?u=",
  },
  NZ: {
    country: "New Zealand",
    country_code: "NZ",
    affiliate_code:
      "https://ticketmaster-nz.tm7569.net/c/3148594/431537/7569?u=",
  },
  PL: {
    country: "Poland",
    country_code: "PL",
    affiliate_code:
      "https://ticketmaster-pl.tm8185.net/c/3148594/458655/8185?u=",
  },
  SE: {
    country: "Sweden",
    country_code: "SE",
    affiliate_code:
      "https://ticketmaster-se.tm7505.net/c/3148594/427737/7505?u=",
  },
  US: {
    country: "The United States of America",
    country_code: "US",
    affiliate_code: "https://ticketmaster.evyy.net/c/3148594/271177/4272?u=",
  },
  ZA: {
    country: "South Africa",
    country_code: "ZA",
    affiliate_code:
      "https://ticketmaster-south-africa.pxf.io/c/3148594/1083446/13855?u=",
  },
};

const DEFAULT_COUNTRY_CODE = "US";

/**
KZFzniwnSyZfZ7v7nE : Sports
*/
const tabList = [
  { key: "All", title: "ALL" },
  // { key: "Music", title: "MUSIC", segmentNames: ["Music"] },
  // { key: "Sports", title: "SPORTS", segmentNames: ["Sports"] },
  // { key: "Classical", title: "CLASSICAL", segmentNames: ["Classical"] },
  // {
  //   key: "Event & Art",
  //   title: "EVENT&ART",
  //   segmentNames: ["Event & Art", "Arts & Theatre"],
  // },
  // { key: "Etc", title: "ETC." },
];

const eventClassificationList = {};

tabList.map((tab) => {
  const key = tab.key;
  eventClassificationList[key] = [];
});

export default {
  name: "Main",
  data: function () {
    return {
      // events: [],
      eventClassificationList: eventClassificationList,
      tabList: tabList,
      dialog: false,
    };
  },
  methods: {
    openWindow(params) {
      this.$swal({
        title: "External Links Disclaimer",
        text: "You will be directed to the external website by clicking OK. \nThe linked website is not under PIA Corporation's control and PIA Corporation is not responsible for the contents of external websites. Please contact the administrator of the website directly for questions on its content.",
        icon: "info",
        buttons: true,
        //dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.open(params, "_self");
        } else {
          return;
        }
      });

      // window.open(params, "_self");
    },
    setTab(tab) {
      this.$emit("selected", tab);
    },
  },
  created() {
    const requestUrl = `${discoveryUrl}${eventsUrl}?classificationId=${classificationIdVirtual}&size=200&apikey=${discoveryApiKey}`;

    console.log(requestUrl);
    fetch(requestUrl)
      .then((response) => response.json())
      .then(function (jsonData) {
        const items = jsonData._embedded.events;

        for (var i = 0; i < items.length; i++) {
          const item = items[i];
          const classifications = item.classifications;
          const classification = classifications[0];
          const segment = classification.segment;
          const segmentName = segment.name;

          let country_code = DEFAULT_COUNTRY_CODE;
          let affiliate_code =affiliateCodeMap[DEFAULT_COUNTRY_CODE].affiliate_code;

          if ("_embedded" in item) {
            const _embedded = item._embedded;
            if ("venues" in _embedded) {
              const venue = _embedded.venues[0];
              if ("country" in venue) {
                const _country = venue.country;
                if ("countryCode" in _country) {
                  const _country_code = _country.countryCode;
                  if (_country_code in affiliateCodeMap) {
                    country_code = _country_code;
                    // test_country_code = _country_code;
                    affiliate_code =
                      affiliateCodeMap[country_code].affiliate_code;
                  }
                }
              }
            }
          }

          const event = new Object();

          if (item.test == true) {
            continue;
          }

          const url = item.url;

          let parse_url = url;
          // Affliate Code On
          // if (url.search("ticketmaster") != -1) {
          //   const split_url = url.split("?u=");
          //   parse_url = split_url[split_url.length - 1];

          //   if (parse_url.search("ticketmaster") != -1) {
          //     const parse_split_url = parse_url.split("https");
          //     parse_url = parse_split_url[parse_split_url.length - 1];
          //     parse_url = `https${parse_url}`;
          //     parse_url = decodeURI(parse_url);
          //   }
          // }
          // console.log(parse_url);

          event.id = item.id;
          event.name = item.name;
          event.url = `${affiliate_code}${parse_url}`;

          // console.log(event.url);

          if ("classifications" in item) {
            event.segmentId = item.classifications[0].segment.id;
            event.segmentName = item.classifications[0].segment.name;
            event.wowwow = item.classifications[0];
          }

          if ("images" in item) {
            event.image = item.images[0].url; // take images[0], if all has fallback=true

            for (var k = 0; k < item.images.length; k++) {
              // console.log('-----------fallback='+typeof(items[i].images[k].fallback));
              if (item.images[k].fallback === false) {
                event.image = item.images[k].url;
              }
            }
          }

          // event date
          if ("dates" in item) {
            var startDate = "";
            var startTime = "";
            var endDate = "";
            var endTime = "";

            var localTimezone = "";

            if ("start" in item.dates) {
              // convert to browser time
              if (typeof item.dates.start.dateTime !== "undefined") {
                var stdate = new Date(item.dates.start.dateTime);
                startDate =
                  stdate.getFullYear() +
                  " " +
                  (stdate.getMonth() + 1) +
                  "/" +
                  stdate.getDate() +
                  "(" +
                  // ["日", "月", "火", "水", "木", "金", "土"][stdate.getDay()] +
                  ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
                    stdate.getDay()
                  ] +
                  ") ";

                startTime =
                  ("0" + stdate.getHours()).slice(-2) +
                  ":" +
                  ("0" + stdate.getMinutes()).slice(-2);

                event.startTime = startDate + startTime;
              }
              // event local time
              var startLocalDate = "";
              var startLocalTime = "";
              if (typeof item.dates.start.localDate !== "undefined") {
                startLocalDate = item.dates.start.localDate;
              }
              if (typeof item.dates.start.localTime !== "undefined") {
                startLocalTime = item.dates.start.localTime;
              }
              event.startLocalTime = startLocalDate + " " + startLocalTime;

              //local timezone
              if (typeof item.dates.timezone !== "undefined") {
                localTimezone = item.dates.timezone;
              }

              event.localTimezone = localTimezone;
            }
            if ("end" in item.dates) {
              if (item.dates.end.dateTime != item.dates.start.dateTime) {
                var eddate = new Date(item.dates.end.dateTime);
                endDate =
                  eddate.getFullYear() +
                  " " +
                  (eddate.getMonth() + 1) +
                  "/" +
                  eddate.getDate() +
                  "(" +
                  // ["日", "月", "火", "水", "木", "金", "土"][eddate.getDay()] +
                  ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
                    eddate.getDay()
                  ] +
                  ") ";

                endTime =
                  ("0" + eddate.getHours()).slice(-2) +
                  ":" +
                  ("0" + eddate.getMinutes()).slice(-2);

                if (endDate != startDate) {
                  event.endTime = endDate + endTime; // different day
                } else {
                  if (endTime != startTime) {
                    event.endTime = endTime; // same day , different time
                  } else {
                    event.endTime = ""; // all same
                  }
                }
                // event local time
                if (typeof item.dates.end.localDate !== "undefined") {
                  event.endLocalTime =
                    item.dates.end.localDate + " " + item.dates.end.localTime;
                }
              }
            }
            event.timeZoneName =
              Intl.DateTimeFormat().resolvedOptions().timeZone;
          }

          if ("place" in item) {
            event.country = item.place.country.name;
            event.city = item.place.city.name;
          }
          if ("_embedded" in item) {
            if ("venues" in item) {
              event.venue = item._embedded.venues[0].name;
            }
          }
          event.show = false;

          if ("description" in item) {
            event.description = item.description;
            // event.description_ja = '';
          }

          //Add By You
          // let isExistSegment = false;
          eventClassificationList["All"].push(event);
          for (var cnt = 0; cnt < tabList.length; cnt++) {
            const tab = tabList[cnt];
            const key = tab.key;
            const segmentNames = tab.segmentNames;
            const eventSegmentName = segmentName;

            if (
              segmentNames != undefined &&
              segmentNames.findIndex((e) => e === eventSegmentName) != -1
            ) {
              eventClassificationList[key].push(event);
              // isExistSegment = true;
              break;
            }
          }
        }
      });
  },
};
</script>
