<template>
  <v-footer
    color="primary"
    padless
    absolute
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link"
        color="white"
        text
        rounded
        class="my-2"
        v-bind:href="link.hlink"
        style="text-transform: none !important;"
      >
        {{ link.title }}
      </v-btn>
      <v-col
        class="primary my-2 text-center white--text"
        cols="12"
      >
        <!-- {{ new Date().getFullYear() }} —  -->
        Copyright © PIA Corporation. All Rights Reserved.
      </v-col>
    </v-row>
  </v-footer>
</template>


<script>
  export default {
    data: () => ({
      links: [
        {title:'FAQ',hlink:'http://t.pia.jp/sp/inbound/faq.html'},
        {title:'Ticket PIA (Japanese Website)',hlink:'https://t.pia.jp/'},
      ],
    }),
  }
</script>