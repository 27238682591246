<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark>
      <div class="d-flex align-center">
        <a href="https://t.pia.jp/">
        <v-img
          alt="Pia Logo"

          contain
          src="https://image.pia.jp/common2/images/logo-pia.png"
          transition="scale-transition"
          width="146"
        />
        </a>
      </div>

    </v-app-bar>

    <v-main>
      <Main/>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Main from './components/Main';
import Footer from './components/Footer';

export default {
  name: 'App',
  //title: 'Global Live Streaming',
  components: {
    Main,
    Footer
},

  data: () => ({
    //
  }),
};
</script>
